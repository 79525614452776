import React, { useRef, useState } from 'react'
import Marquee from "react-fast-marquee"
import './app.css';
import { useSearchParams } from 'react-router-dom';
import { ExternalLink } from 'react-external-link';
import { FaWhatsapp } from 'react-icons/fa';


const App = () => {
  // const [da, setDa] = useState(1)
  // const [ho, setHo] = useState(1)
  // const [min, setMin] = useState(1)
  // const [sec, setSec] = useState(1)

  const [searchParams, setSearchParams] = useSearchParams();

  const [urlPara, setUrlPara] = useState("")
  const inputRef = useRef(null);

  // const countDate = new Date("August 19,2022 00:00:00")
  // function timeCountDown() {
  //   const nowDate = new Date()
  //   const totalSeconds = (countDate - nowDate) / 1000;
  //   const days = Math.floor(totalSeconds / 3600 / 24);
  //   const hours = Math.floor(totalSeconds / 3600) % 24;
  //   const minutes = Math.floor(totalSeconds / 60) % 60;
  //   const seconds = Math.floor(totalSeconds) % 60;
  //   setDa(formatTime(days))
  //   setHo(formatTime(hours))
  //   setMin(formatTime(minutes))
  //   setSec(formatTime(seconds))

  // }
  // function formatTime(time) {
  //   return time > 10 ? time : `0${time}`
  // }

  // setInterval(() => {
  //   timeCountDown()
  // }, 1000);

 
  const handleClick = () => { 
    setSearchParams({ query: inputRef.current.value });
    setUrlPara(inputRef.current.value); 
  }

  const url = `whatsapp://send?text=इस जन्माष्टमी पर ${searchParams.get("name")} ने आपके लिए कुछ बनाया है इस लिंक पर क्लिक करके देखें  👇👇👇👇👇👇👇👇👇 ${window.location.href}${urlPara}` 
  return (
    <>
      <div className="container shadow min-vh-100 py-2">
        <div className="row"> 
          <div className=" text-center">
            <h3 className="animate-charcter"> { searchParams.get("name") ? searchParams.get("name"):"shashwat alight" } </h3> 
            <h2> की तरफ से आपको और आपके परिवार को {/* Advance में  </h2>
            <h1>  {da} days, {ho} hours, {min} minutes, {sec} seconds </h1>
            <h2>पहले */} जन्माष्टमी की हार्दिक शुभकामनाएं </h2> 
          </div>
        </div>
        <hr />
        <div className="row">
          <p style={{ textAlign: "justify", marginRight: 15 }}>

            पलक झपकते ही सभी कामनाओं को पूरा करने वाले कृष्ण की पूजा के लिए भाद्रपद मास की कृष्ण पक्ष की अष्टमी को अत्यंत ही शुभ माना गया है क्योंकि द्वापर युग में इसी पावन तिथि पर भगवान श्रीकृष्ण ने पृथ्वी पर जन्म लिया था. श्रीकृष्ण जन्माष्टमी का यह पर्व इस साल <b> 19 अगस्त 2022 </b> को अर्धरात्रि में मनाया जाएगा.
          </p>
          <div className=" text-center mb-5">
            <h3 className="animate-charcter"> आप सभी को श्री कृष्ण जन्माष्टमी की हार्दिक शुभकामनाएं  </h3>

          </div> 

          <div>
            <img src="https://c.tenor.com/blVNs5wlJ-kAAAAM/janmastmi-happy-janmastmi.gif" class="rounded float-start" alt="kanha" style={{ width: "130px", height: "200px" }} />
            <img src="https://c.tenor.com/3OoD7IUEKFYAAAAC/hare-krishna-hare-ram.gif" class="rounded float-end" alt="kanha" style={{ width: "130px", height: "200px" }} />
          </div>
          <img
            src=" https://www.gazabnews.com/wp-content/uploads/2017/07/eeb7341995f916ec202ad73e5d7a249e.gif "
            className="img-fluid mt-3"
            alt=" kanha"
          />
          <div style={{ backgroundColor: "red" }} className="newEl">
            <Marquee gradient={false}>
              <b> कृष्ण की महिमा, कृष्ण का प्यार;</b>
            </Marquee>
          </div>
          <div style={{ backgroundColor: "yellow" }} className="newEl">
            <Marquee gradient={false}>
              <b>
                कृष्ण में श्रद्धा, कृष्ण से ही संसार;</b>
            </Marquee>
          </div>
          <div style={{ backgroundColor: "skyblue" }} className="newEl">
            <Marquee  gradient={false}>
              <b>
                मुबारक हो आप सबको  </b>
            </Marquee>
          </div>
          <div style={{ backgroundColor: "sky" }} className="newEl">
            <Marquee gradient={false} >
              <b>
                जन्माष्टमी का त्योहार!</b>
            </Marquee>
          </div>
          <span className="newSp">
            <Marquee gradient={false} >
              <i>
                <b> जन्माष्टमी हर साल भाद्रपद मास के कृष्ण पक्ष की अष्टमी तिथि के साथ रोहिणी नक्षत्र के दिन मनाई जाती है </b>
              </i>
            </Marquee>
          </span>
        </div>

        <img
            src="https://media1.giphy.com/media/wLTJn2Tnbi240/200w.webp?cid=ecf05e47k9g2is8glyx8ln7s3warb72u3tgmyln3jk756gpy&rid=200w.webp&ct=g "
            className="img-fluid w-100 mt-4"
            alt=" kanha"
          />

         <p className='mt-5'> <b>   
           <h2 className='text-center'> श्री कृष्ण के जन्म की कहानी</h2>
'द्वापर युग में भोजवंशी राजा उग्रसेन मथुरा में राज्य करता था। उसके आततायी पुत्र कंस ने उसे गद्दी से उतार दिया और स्वयं मथुरा का राजा बन बैठा। कंस की एक बहन देवकी थी, जिसका विवाह वसुदेव नामक यदुवंशी सरदार से हुआ था। 
 
 एक समय कंस अपनी बहन देवकी को उसकी ससुराल पहुंचाने जा रहा था।
  
 रास्ते में आकाशवाणी हुई- 'हे कंस, जिस देवकी को तू बड़े प्रेम से ले जा रहा है, उसी में तेरा काल बसता है। इसी के गर्भ से उत्पन्न आठवां बालक तेरा वध करेगा।' यह सुनकर कंस वसुदेव को मारने के लिए उद्यत हुआ।
  
 तब देवकी ने उससे विनयपूर्वक कहा- 'मेरे गर्भ से जो संतान होगी, उसे मैं तुम्हारे सामने ला दूंगी। बहनोई को मारने से क्या लाभ है?'
  
 कंस ने देवकी की बात मान ली और मथुरा वापस चला आया। उसने वसुदेव और देवकी को कारागृह में डाल दिया।
  
 वसुदेव-देवकी के एक-एक करके सात बच्चे हुए और सातों को जन्म लेते ही कंस ने मार डाला। अब आठवां बच्चा होने वाला था। कारागार में उन पर कड़े पहरे बैठा दिए गए। उसी समय नंद की पत्नी यशोदा को भी बच्चा होने वाला था।
  
 उन्होंने वसुदेव-देवकी के दुखी जीवन को देख आठवें बच्चे की रक्षा का उपाय रचा। जिस समय वसुदेव-देवकी को पुत्र पैदा हुआ, उसी समय संयोग से यशोदा के गर्भ से एक कन्या का जन्म हुआ, जो और कुछ नहीं सिर्फ 'माया' थी। 
  
 जिस कोठरी में देवकी-वसुदेव कैद थे, उसमें अचानक प्रकाश हुआ और उनके सामने शंख, चक्र, गदा, पद्म धारण किए चतुर्भुज भगवान प्रकट हुए। दोनों भगवान के चरणों में गिर पड़े। तब भगवान ने उनसे कहा- 'अब मैं पुनः नवजात शिशु का रूप धारण कर लेता हूं।
  
 तुम मुझे इसी समय अपने मित्र नंदजी के घर वृंदावन में भेज आओ और उनके यहां जो कन्या जन्मी है, उसे लाकर कंस के हवाले कर दो। इस समय वातावरण अनुकूल नहीं है। फिर भी तुम चिंता न करो। जागते हुए पहरेदार सो जाएंगे, कारागृह के फाटक अपने आप खुल जाएंगे और उफनती अथाह यमुना तुमको पार जाने का मार्ग दे देगी।'
  
 उसी समय वसुदेव नवजात शिशु-रूप श्रीकृष्ण को सूप में रखकर कारागृह से निकल पड़े और अथाह यमुना को पार कर नंदजी के घर पहुंचे। वहां उन्होंने नवजात शिशु को यशोदा के साथ सुला दिया और कन्या को लेकर मथुरा आ गए। कारागृह के फाटक पूर्ववत बंद हो गए।
  
 अब कंस को सूचना मिली कि वसुदेव-देवकी को बच्चा पैदा हुआ है।
  
 उसने बंदीगृह में जाकर देवकी के हाथ से नवजात कन्या को छीनकर पृथ्वी पर पटक देना चाहा, परंतु वह कन्या आकाश में उड़ गई और वहां से कहा- 'अरे मूर्ख, मुझे मारने से क्या होगा? तुझे मारनेवाला तो वृंदावन में जा पहुंचा है। वह जल्द ही तुझे तेरे पापों का दंड देगा।' यह है कृष्ण जन्म की कथा। <br /> <br /> <br />  <br /><br /> </b>
         </p>
        <div className="fixed-bottom mb-5 d-flex justify-content-center">
          <form className="row">
            <div className="col-auto">
              <input
                type="text"
                className="form-control"
                 style={{
                  backgroundColor:"yellow",
                  color:"black"
                 }}
                placeholder="👉 यहाँ अपना नाम लिखें..."

                ref={inputRef}
                id="message"
                name="name"
              />
            </div>
            <div className="col-auto">
              <button className="btn btn-primary mb-3" onClick={handleClick} >
              देखें 👉 
              </button>
            </div>
          </form>

        </div>
        <div class=" footerBtn "  >
          <ExternalLink href={url}  >
            <span ><FaWhatsapp /> <b style= {{fontSize:"20px", color:"black"}}>👉 यहाँ से सबको भेजो 👈</b> <FaWhatsapp /> </span>
          </ExternalLink>
        </div>
        
      </div>

    </>
  )
}


export default App